import React, { lazy } from 'react';
const Dashboard = lazy(() => import('../views/admin/Dashboard'));
const MyProfile = lazy(() => import('../views/minister/MyProfile'));

export const ministerArray = [
  {
    path: '/minister/dashboard',
    element: <Dashboard page="/admin/dashboard" />,
  },
  {
    path: '/minister/myprofile',
    element: <MyProfile page="/admin/myprofilee" />,
  },
  {
    path: '/minister/patient-statistics',
    element: <Dashboard page="/admin/patient-statistics" />,
  },
  {
    path: 'minister/login',
    element: <Dashboard page="/admin/dashboard" />,
  },

  {
    path: '/',
    element: <Dashboard page="/admin/dashboard" />,
  },
];
