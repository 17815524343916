import React, { lazy } from 'react';
const Patients = lazy(() => import('../views/clerk/tables/Patients'));
const AddPatient = lazy(() => import('../views/clerk/forms/AddPatient'));
const EditPatient = lazy(() => import('../views/patient/forms/EditPatient'));
// const PatientProfile = lazy(() =>
//   import('../views/patient/Profile'),
// );
const ClerkDashboard = lazy(() => import('../views/clerk/Dashboard'));
const AddAppointment = lazy(() =>
  import('../views/patient/forms/AddAppointment'),
);
const Myprofile = lazy(() => import('../views/clerk/Myprofile'));
const NewVisit = lazy(() => import('../views/clerk/forms/NewVisit'));
const NewTriageReport = lazy(() =>
  import('../views/clerk/forms/NewTriageReport'),
);
const ViewTriageReports = lazy(() =>
  import('../views/clerk/ViewTriageReports'),
);
const ViewTriageReport = lazy(() => import('../views/clerk/ViewTriageReport'));
const TriageReportsAll = lazy(() =>
  import('../views/clerk/tables/TriageReportsAll'),
);
const Aside = lazy(() => import('../containers/aside/ClerkAside'));
const ViewAppointments = lazy(() => import('../views/clerk/ViewAppointments'));
const ViewAppointment = lazy(() => import('../views/clerk/ViewAppointment'));
const EditAppointment = lazy(() => import('../views/clerk/EditAppointment'));
const NotFound = lazy(() => import('../components/404/NotFound'));
const Breadcrums = lazy(() => import('../components/breadcrums/Breadcrums'));
const Header = lazy(() => import('../containers/header/Header'));
const Hero = lazy(() => import('../components/hero/Hero'));
const ActiveDoc = lazy(() => import('../components/cards/patients/ActiveDoc'));
const PatientDashboard = lazy(() => import('../views/doctor/PatientDashboard'));
const clerkTitle = 'Data Entry Clerk';
const clerkPath = 'clerk';

export const clerkArray = [
  {
    path: '/clerk/dashboard',
    element: (
      <>
        <Header title={clerkTitle} path={clerkPath} />
        <Aside page="/clerk/dashboard" />

        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '', name: 'Dashboard' },
              ]}
            />
            <Hero quote="Have a nice day" />
            <ClerkDashboard />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/clerk/myprofile',
    element: (
      <>
        <Header title={clerkTitle} path={clerkPath} />
        <Aside page="/clerk/myprofile" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '', name: 'My Profile' },
              ]}
            />
            <Myprofile page="/clerk/myprofile" />
            {/* //left to analyse origianl stucture before moving asisde here */}
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/appointments',
    element: (
      <>
        <Header title={clerkTitle} path={clerkPath} />
        <Aside page="/patient/appointments" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '', name: 'Appointment List' },
              ]}
            />
            <ViewAppointments />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/view-appointment/:id/:patientID',
    element: (
      <>
        <Header title="Data Entry Clerk" path="clerk" />
        <Aside page="/patient/appointments" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '/patient/appointments', name: 'Appointment List' },
                { path: '', name: 'Patient Appointment' },
              ]}
            />
            <ViewAppointment />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/edit-appointment/:id/:patientID',
    element: (
      <>
        <Header title="Data Entry Clerk" path="clerk" />
        <Aside page="/patient/appointments" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '/patient/appointments', name: 'Appointment List' },
                { path: '', name: 'Edit Appointment' },
              ]}
            />
            <EditAppointment />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/list',
    element: (
      <>
        <Header title={clerkTitle} path={clerkPath} />
        <Aside page="/patient/list" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '', name: 'Patient List' },
              ]}
            />
            <ActiveDoc />
            <Patients />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/new-visit/:patientID',
    element: (
      <>
        <Header title="Data Entry Clerk" path="clerk" />
        <Aside page="/patient/appointments" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '', name: 'New Visit' },
              ]}
            />
            <NewVisit />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/triage-reports/',
    element: (
      <>
        <Header title={clerkTitle} path={clerkPath} />
        <Aside page="/patient/triage-reports" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '', name: 'Triage Reports' },
              ]}
            />
            <ActiveDoc />
            <TriageReportsAll />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/new-triage-report/:patientID',
    element: (
      <>
        <Header title="Data Entry Clerk" path="clerk" />
        <Aside page="/patient/triage-reports" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '', name: 'Triage Report' },
              ]}
            />
            <NewTriageReport />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/view-triage-report/:triageID',
    element: (
      <>
        <Header title="Data Entry Clerk" path="clerk" />
        <Aside page="/patient/triage-reports" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '/patient/triage-reports', name: 'Triage Reports' },
                { path: '', name: 'Triage Report' },
              ]}
            />
            <ViewTriageReport />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/view-triage-reports/:patientID',
    element: (
      <>
        <Header title="Data Entry Clerk" path="clerk" />
        <Aside page="/patient/triage-reports" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '/patient/triage-reports', name: 'Triage Reports' },
                { path: '', name: 'Triage Report' },
              ]}
            />
            <ViewTriageReports />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/add-patient',
    element: (
      <>
        <Header title={clerkTitle} path={clerkPath} />
        <Aside page="/patient/add-patient" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '', name: 'Add Patient' },
              ]}
            />
            <ActiveDoc />
            <AddPatient />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/add-appointment/:patientID' /*remember to always pass the same id name used in the component params*/,
    element: (
      <>
        (
        <Header title={clerkTitle} path={clerkPath} />
        <Aside page="/patient/add-appointment" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '/patient/appointments', name: 'Appointment List' },
                { path: '', name: 'Add Appointment' },
              ]}
            />
            <AddAppointment />
          </div>
        </div>
        )
      </>
    ),
  },
  {
    path: '/patient/dashboard/:patientID',
    element: (routeProps = {}) => (
      <>
        <Header title={clerkTitle} path={clerkPath} />
        <Aside page="/doctor/dashboard" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '', name: 'Patient Dashboard' },
              ]}
            />
            <PatientDashboard {...routeProps} page="/doctor/dashboard" />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/edit-patient/:patientID',
    element: (
      <>
        <Header title={clerkTitle} path={clerkPath} />
        <Aside page="/patient/list" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '', name: 'Edit Patient' },
              ]}
            />{' '}
            <EditPatient />
          </div>
        </div>
      </>
    ),
  },
  {
    path: 'clerk/login',
    element: (
      <>
        <Header title={clerkTitle} path={clerkPath} />
        <Aside page="/data-entry/dashboard" />

        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '', name: 'Dashboard' },
              ]}
            />
            <Hero quote="Have a nice day" />
            <ClerkDashboard />
          </div>
        </div>
      </>
    ),
  },

  {
    path: '/doctor/login',
    element: (
      <>
        <Header title={clerkTitle} path={clerkPath} />
        <Aside page="/data-entry/dashboard" />

        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '', name: 'Dashboard' },
              ]}
            />
            <Hero quote="Have a nice day" />
            <ClerkDashboard />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/admin/login',
    element: (
      <>
        <Header title={clerkTitle} path={clerkPath} />
        <Aside page="/data-entry/dashboard" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '', name: 'Dashboard' },
              ]}
            />
            <Hero quote="Have a nice day" />
            <ClerkDashboard />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/',
    element: (
      <>
        <Header title={clerkTitle} path={clerkPath} />
        <Aside page="/data-entry/dashboard" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/clerk/dashboard', name: 'Clerk' },
                { path: '', name: 'Dashboard' },
              ]}
            />
            <Hero quote="Have a nice day" />
            <ClerkDashboard />
          </div>
        </div>
      </>
    ),
  },
];
