import React, { lazy } from 'react';
const EditPatientReadMe = lazy(() =>
  import('../views/patient/forms/EditPatientReadMe'),
);
const Dashboard = lazy(() => import('../views/patient/Dashboard'));
const PatientProfile = lazy(() => import('../views/patient/Profile'));
const Aside = lazy(() => import('../containers/aside/PatientAside'));
const Breadcrums = lazy(() => import('../components/breadcrums/Breadcrums'));
const Header = lazy(() => import('../containers/header/Header'));
const AddAppointment = lazy(() =>
  import('../views/patient/forms/AddAppointment'),
);

// const AddAppointment = lazy(() =>
//   import('../views/patient/forms/AddAppointment'),
// );

export const patientArray = [
  // {
  //   path: '/patient/add-appointment/:patientID' /*remember to always pass the same id name used in the component params*/,
  //   element: <AddAppointment page="/patient/add-appointment" />,
  // },
  {
    path: '/patient/myprofile',
    element: <PatientProfile page="/patient/myprofile" />,
  },
  {
    path: '/patient/edit-profile',
    element: (
      <>
        <Header />
        <Aside page="/patient/dashboard" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/patient/dashboard', name: 'Dashboard' },
                { path: '', name: 'Edit Profile' },
              ]}
            />{' '}
            <EditPatientReadMe />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/dashboard',
    element: (
      <>
        <Header title="Patient" path="patient" />
        <Aside page="/patient/dashboard" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[{ path: '', name: 'Patient Dashboard' }]}
            />
            <Dashboard />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/login',
    element: (
      <>
        <Header title="Patient" path="patient" />
        <Aside page="/patient/dashboard" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[{ path: '', name: 'Patient Dashboard' }]}
            />
            <Dashboard />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/',
    element: (
      <>
        <Header title="Patient" path="patient" />
        <Aside page="/patient/dashboard" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[{ path: '', name: 'Patient Dashboard' }]}
            />
            <Dashboard />
          </div>
        </div>
      </>
    ),
  },
];
