import React, { lazy } from 'react';
const Patients = lazy(() => import('../views/clerk/tables/Patients'));
const AddPatient = lazy(() => import('../views/clerk/forms/AddPatient'));
const EditPatient = lazy(() => import('../views/patient/forms/EditPatient'));
const PatientDashboard = lazy(() => import('../views/doctor/PatientDashboard'));
const Dashboard = lazy(() => import('../views/doctor/Dashboard'));
const DiseaseHistory = lazy(() => import('../views/admin/DiseaseHistory'));
const Myprofile = lazy(() => import('../views/doctor/--Myprofile'));
const Breadcrums = lazy(() => import('../components/breadcrums/Breadcrums'));
const Aside = lazy(() => import('../containers/aside/DoctorAside'));
const Header = lazy(() => import('../containers/header/Header'));
const ActiveDoc = lazy(() => import('../components/cards/patients/ActiveDoc'));

const AddAppointment = lazy(() =>
  import('../views/patient/forms/AddAppointment'),
);
const ViewAppointments = lazy(() => import('../views/clerk/ViewAppointments'));
const NewVisit = lazy(() => import('../views/clerk/forms/NewVisit'));
const docTitle = 'Doctor';
const docPath = 'doctor';

export const doctorArray = [
  {
    path: '/doctor/dashboard',
    element: (
      <>
        <Header title={docTitle} path={docPath} />
        <Aside page="/doctor/dashboard" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/doctor/dashboard', name: 'Doctor' },
                { path: '', name: 'Dashboard' },
              ]}
            />
            <Dashboard />
          </div>
        </div>
      </>
    ),
  },

  {
    path: '/patient/list',
    element: (
      <>
        <Header title={docTitle} path={docPath} />
        <Aside page="/patient/list" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/doctor/dashboard', name: 'Doctor' },
                { path: '', name: 'Patient List' },
              ]}
            />
            <ActiveDoc />
            <Patients />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/add-appointment/:patientID' /*remember to always pass the same id name used in the component params*/,
    element: (
      <>
        (
        <Header title={docTitle} path={docPath} />
        <Aside page="/patient/add-appointment" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/doctor/dashboard', name: 'Doctor' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '/patient/appointments', name: 'Appointment List' },
                { path: '', name: 'Add Appointment' },
              ]}
            />
            <AddAppointment />
          </div>
        </div>
        )
      </>
    ),
  },
  {
    path: '/patient/appointments',
    element: (
      <>
        <Header title={docTitle} path={docPath} />
        <Aside page="/patient/appointments" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/doctor/dashboard', name: 'Doctor' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '', name: 'Appointment List' },
              ]}
            />
            <ViewAppointments />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/new-visit/:patientID',
    element: (
      <>
        <Header title={docTitle} path={docPath} />
        <Aside page="/patient/appointments" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/doctor/dashboard', name: 'Doctor' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '', name: 'New Visit' },
              ]}
            />
            <NewVisit />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/add-patient',
    element: (
      <>
        <Header title={docTitle} path={docPath} />
        <Aside page="/patient/add-patient" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/doctor/dashboard', name: 'Doctor' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '', name: 'Add Patient' },
              ]}
            />
            <ActiveDoc />
            <AddPatient />
          </div>
        </div>
      </>
    ),
  },

  {
    path: '/patient/dashboard/:patientID',
    element: (routeProps = {}) => (
      <>
        <Header title={docTitle} path={docPath} />
        <Aside page="/doctor/dashboard" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/doctor/dashboard', name: 'Doctor' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '', name: 'Patient Dashboard' },
              ]}
            />
            <PatientDashboard {...routeProps} page="/doctor/dashboard" />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/doctor/myprofile',
    element: (
      <>
        <Header title={docTitle} path={docPath} />
        <Aside page="/doctor/myprofile" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/doctor/dashboard', name: 'Doctor' },
                { path: '', name: 'My Profile' },
              ]}
            />
            <ActiveDoc />
            <Myprofile />
          </div>
        </div>
      </>
    ),
  },

  {
    path: '/patient/add-patient',
    element: (
      <>
        <Header title={docTitle} path={docPath} />
        <Aside page="/patient/add-patient" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/doctor/dashboard', name: 'Doctor' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '', name: 'Add Patient' },
              ]}
            />
            <ActiveDoc />
            <AddPatient />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/edit-patient/:patientID',
    element: (
      <>
        <Header title={docTitle} path={docPath} />
        <Aside page="/patient/list" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/doctor/dashboard', name: 'Doctor' },
                { path: '/patient/list', name: 'Patient List' },
                { path: '', name: 'Edit Patient' },
              ]}
            />{' '}
            <EditPatient />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/patient/table/complications',
    element: (
      <>
        <Header title={docTitle} path={docPath} />
        <Aside page="/patient/table/complications" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/doctor/dashboard', name: 'Doctor' },
                { path: '', name: 'Medical Complications' },
              ]}
            />
            <DiseaseHistory />
          </div>
        </div>
      </>
    ),
  },
  {
    path: 'doctor/login',
    element: (
      <>
        <Header title="Doctor" path="doctor" />
        <Aside page="/doctor/dashboard" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/doctor/dashboard', name: 'Doctor' },
                { path: '', name: 'Dashboard' },
              ]}
            />
            <Dashboard />
          </div>
        </div>
      </>
    ),
  },
  {
    path: '/',
    element: (
      <>
        <Header title="Doctor" path="doctor" />
        <Aside page="/doctor/dashboard" />
        <div className="page-wrapper">
          <div className="content">
            <Breadcrums
              breadcrums={[
                { path: '/doctor/dashboard', name: 'Doctor' },
                { path: '', name: 'Dashboard' },
              ]}
            />
            <Dashboard />
          </div>
        </div>
      </>
    ),
  },
];
