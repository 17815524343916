export function getDashboardPathByRole(role) {
  switch (role) {
    case 0:
      return '/admin/dashboard';
    case 1:
      return '/minister/dashboard';
    case 2:
      return '/doctor/dashboard';
    case 3:
      return '/clerk/dashboard';
    case 4:
      return '/patient/dashboard';
    default:
      return '/';
  }
}

export function getLogoutPathByRole(role) {
  switch (role) {
    case 0:
      return '/admin/login';
    case 1:
      return '/minister/login';
    case 2:
      return '/doctor/login';
    case 3:
      return '/clerk/login';
    case 4:
      return '/patient/login';
    default:
      return '/';
  }
}

export function getAsideByRole(role) {
  if (role === 0) {
    return 'AdminAside';
  } else if (role === 1) {
    return 'MinisterAside';
  } else if (role === 2) {
    return 'DoctorAside';
  } else if (role === 3) {
    return 'ClerkAside';
  } else if (role === 4) {
    return 'PatientAside';
  }
  return [];
}

export function getTitlesByRole(role) {
  if (role === 0) {
    return [
      {
        breadcrumPath: '/admin/dashboard',
        headerPath: 'admin',
        name: 'Admin',
      },
    ];
  } else if (role === 1) {
    return [
      {
        breadcrumPath: '/minister/dashboard',
        headerPath: 'minister',
        name: 'Health Minister',
      },
    ];
  } else if (role === 2) {
    return [
      {
        breadcrumPath: '/doctor/dashboard',
        headerPath: 'doctor',
        name: 'Doctor',
      },
    ];
  } else if (role === 3) {
    return [
      {
        breadcrumPath: '/clerk/dashboard',
        headerPath: 'clerk',
        name: 'Clerk',
      },
    ];
  } else if (role === 4) {
    return [
      {
        breadcrumPath: '/patient/dashboard',
        headerPath: 'patient',
        name: 'Patient',
      },
    ];
  }
  return [];
}
