import React, { lazy } from 'react';
const AdminDashboard = lazy(() => import('../views/admin/Dashboard'));
const Dashboard = lazy(() => import('../views/admin/Dashboard'));
const DiseaseHistory = lazy(() => import('../views/admin/DiseaseHistory'));

const Settings = lazy(() => import('../views/admin/Settings'));
const MyProfile = lazy(() => import('../views/admin/MyProfile'));

export const adminArray = [
  {
    path: '/admin/dashboard',
    element: <Dashboard page="/admin/dashboard" />,
  },
  {
    path: '/admin/myprofile',
    element: <MyProfile page="/admin/myprofilee" />,
  },
  {
    path: '/admin/patient-statistics',
    element: <Dashboard page="/admin/patient-statistics" />,
  },

  {
    path: '/admin/settings',
    element: <Settings page="/admin/settings" />,
  },
  {
    path: 'admin/login',
    element: <Dashboard page="/admin/dashboard" />,
  },

  {
    path: '/',
    element: <Dashboard page="/admin/dashboard" />,
  },
];
