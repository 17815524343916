import { useAuth } from '../../AuthContext';
import { getDashboardPathByRole } from '../../utils/roleAuthentication';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, role, loggedIn }) => {
  const { token } = useAuth();
  const isAuthenticated = !!token;

  if (isAuthenticated || loggedIn) {
    return children;
  } else {
    // window.location.pathname = dashboardPath;
    const dashboardPath = getDashboardPathByRole(role);
    return <Navigate to={dashboardPath} replace />;
  }

  // It's better to have an explicit return for all paths in the component
  return null;
};

export default ProtectedRoute;

// import { useAuth } from '../../AuthContext';

// const ProtectedRoute = ({ children }) => {
//   const { token } = useAuth();
//   const isAuthenticated = !!token;

//   if (isAuthenticated) {
//     return children;
//   } else {
//     window.location.pathname = '/login';
//   }
// };

// export default ProtectedRoute;
