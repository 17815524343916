import React, {
  useEffect,
  useState,
  lazy,
  Suspense,
  createContext,
  useContext,
} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import ProtectedRoute from './components/middleware/ProtectedRoute';
import { Dna } from 'react-loader-spinner';
import { ToastContainer } from 'react-toastify';
import * as auth from './utils/auth';
import { AuthProvider } from './AuthContext';
import { adminArray } from './routes/adminArray';
import { doctorArray } from './routes/doctorArray';
import { ministerArray } from './routes/ministerArray';
import { patientArray } from './routes/patientArray';
import { clerkArray } from './routes/clerkArray';
import { getDashboardPathByRole } from './utils/roleAuthentication';

const LoginDoctor = lazy(() => import('./views/doctor/forms/Login'));
const LoginAdmin = lazy(() => import('./views/admin/forms/Login'));
const LoginClerk = lazy(() => import('./views/clerk/forms/Login'));
const LoginPatient = lazy(() => import('./views/patient/forms/Login'));
const LoginMinister = lazy(() => import('./views/minister/forms/Login'));
const Logout = lazy(() => import('./views/doctor/forms/Logout'));
const PatientProfile = lazy(() => import('./views/patient/Profile'));
const Welcome = lazy(() => import('./views/Welcome'));
const NotFound = lazy(() => import('./components/404/NotFound'));

function getRoutesByRole(role) {
  if (role === 0) {
    return adminArray;
  } else if (role === 1) {
    return ministerArray;
  } else if (role === 2) {
    return doctorArray;
  } else if (role === 3) {
    return clerkArray;
  } else if (role === 4) {
    return patientArray;
  }
  return [];
}

// Create Context object
const UserContext = createContext(null);
export const RoleArrayContext = React.createContext([]);

// Export useContext Hook
export function useUser() {
  return useContext(UserContext);
}

function Redirector({ loggedIn, role, redirectFromLogin }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(null);

  useEffect(() => {
    // Check if the current path starts with "/patient/medcard/"
    const isMedcardPath = location.pathname.startsWith('/patient/medcard/');

    if (
      !redirectFromLogin &&
      ![
        '/logout',
        '/clerk/login',
        '/doctor/login',
        '/patient/login',
        '/minister/login',
        '/admin/login',
      ].includes(location.pathname) &&
      !isMedcardPath && // Skip redirect for medcard paths
      prevLocation !== location.pathname
    ) {
      const dashboardPath = getDashboardPathByRole(role);
      navigate(dashboardPath);
    }
    setPrevLocation(location.pathname);
  }, [redirectFromLogin, location]);

  return null; // this component does not render anything
}

function App() {
  const [state, setState] = useState({
    user: {},
    role: '',
    loggedIn: false,
    error: '',
    token: localStorage.getItem('token') || null, // Only read from localStorage once
    redirectFromLogin: false,
  });

  const setToken = (newToken) => {
    localStorage.setItem('token', newToken);
    setState((prevState) => ({ ...prevState, token: newToken }));
  };

  const setError = (errorMsg) => {
    setState((prevState) => ({ ...prevState, error: errorMsg }));
  };
  // Destructure state variables for easier usage
  const { user, role, loggedIn, error, token, redirectFromLogin } = state;

  useEffect(() => {
    if (token) {
      auth
        .checkToken(token)
        .then((res) => {
          if (res) {
            setState((prevState) => ({
              ...prevState,
              loggedIn: true,
              role: res.role,
              redirectFromLogin: true,
              user: res,
            }));
          } else {
            console.error('Invalid email or password');
            setState((prevState) => ({ ...prevState, loggedIn: false }));
          }
        })
        .catch((err) => {
          console.error('An error occurred');
          console.error(err);
          setState((prevState) => ({ ...prevState, loggedIn: false }));
        });
    } else {
      setState({ ...state, loggedIn: false, redirectFromLogin: false });
      console.error('error loggin in');
    }
  }, [token]);

  const loginRequest = (email, password, tableName) => {
    auth
      .authorize(email, password, tableName)
      .then((data) => {
        if (data.token) {
          localStorage.setItem('token', data.token);
          setState((prevState) => ({
            ...prevState,
            user: data.person,
            role: data.person.role,
            loggedIn: true,
            redirectFromLogin: true,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            error: 'Invalid email or password',
          }));
        }
      })
      .catch((err) => {
        console.error(err);
        setState((prevState) => ({
          ...prevState,
          loggedIn: false,
          error: 'Invalid username or password',
        }));
      });
  };

  let routes = getRoutesByRole(role);

  function handleLogout() {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      setState((prevState) => ({
        ...prevState,
        loggedIn: false,
        redirectFromLogin: false,
      }));
      console.error('token expired');
    } else {
      console.error('You were logged out');
    }
  }

  return (
    <AuthProvider value={{ token, setToken }}>
      <ToastContainer />
      <div className="main-wrapper">
        <BrowserRouter>
          <UserContext.Provider value={{ user, handleLogout }}>
            <RoleArrayContext.Provider value={role}>
              <Suspense
                fallback={
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-loader"
                  />
                }
              >
                <Redirector
                  loggedIn={loggedIn}
                  role={role}
                  redirectFromLogin={redirectFromLogin}
                />

                <Routes>
                  <Route
                    path="/patient/medcard/:patientID"
                    element={<PatientProfile />}
                  />
                  {!loggedIn ? (
                    <>
                      <Route
                        path="/doctor/login"
                        element={
                          <LoginDoctor
                            loginRequest={loginRequest}
                            error={error}
                            setError={setError}
                          />
                        }
                      />
                      <Route
                        path="/patient/login"
                        element={
                          <LoginPatient
                            loginRequest={loginRequest}
                            error={error}
                            setError={setError}
                          />
                        }
                      />
                      <Route
                        path="minister/login"
                        element={
                          <LoginMinister
                            loginRequest={loginRequest}
                            error={error}
                            setError={setError}
                          />
                        }
                      />
                      <Route
                        path="admin/login"
                        element={
                          <LoginAdmin
                            loginRequest={loginRequest}
                            error={error}
                            setError={setError}
                          />
                        }
                      />
                      <Route
                        path="/clerk/login"
                        element={
                          <LoginClerk
                            loginRequest={loginRequest}
                            error={error}
                            setError={setError}
                          />
                        }
                      />
                      <Route path="/logout" element={<Welcome />} />
                    </>
                  ) : (
                    <>
                      {(routes || []).map(({ path, element, props }, index) => (
                        <Route
                          key={index}
                          path={path}
                          element={
                            <ProtectedRoute role={role} loggedIn={loggedIn}>
                              {typeof element === 'function'
                                ? element({ ...props })
                                : element}
                            </ProtectedRoute>
                          }
                        />
                      ))}

                      <Route
                        path="/logout"
                        element={<Logout handleLogout={handleLogout} />}
                      />
                    </>
                  )}

                  <Route path="/" element={<Welcome />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
            </RoleArrayContext.Provider>
          </UserContext.Provider>
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}

export default App;
